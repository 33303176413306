import React, { useState, useRef } from "react";
import { StorageApi } from "@mihhdu/api-client";
import { FaFileImage, FaSpinner } from "react-icons/fa6";

interface FileUploadProps {
  onUploadComplete: (htmlTag: string) => void;
  storage: StorageApi;
  tenant: string;
  customClass?: string;
}

function FileUpload({
  onUploadComplete,
  storage,
  tenant,
  customClass = "",
}: FileUploadProps) {
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      await uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    try {
      setIsUploadInProgress(true);
      await storage.tenantUploadPost(tenant, `filename="${file.name}"`, file);
      const url = `media/${tenant}/${file.name}`;

      // Determine the appropriate tag based on the file type (MIME type)
      let htmlTag = "";
      if (file.type.startsWith("video")) {
        htmlTag = `<video src="${url}" controls></video>`;
      } else if (file.type.startsWith("audio")) {
        htmlTag = `<audio src="${url}" controls></audio>`;
      } else if (file.type.startsWith("image")) {
        htmlTag = `<figure><img src="${url}" alt="Image"><figcaption>Image file</figcaption></figure>`;
      } else {
        console.error("Unsupported file type.");
        return;
      }

      // Call the onUploadComplete callback with the HTML tag
      onUploadComplete(htmlTag);

      // Reset the file input for next use
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Upload failed. Please try again.");
    } finally {
      setIsUploadInProgress(false);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`tiptap-file-upload ${customClass}`}>
      <input
        ref={fileInputRef}
        type="file"
        className="tiptap-file-input"
        accept=".jpg, .jpeg, .png, .gif, .webp, .bmp, .svg, .tiff, .ico, .mp3, .ogg, .wav, .flac, .aac, .wma, .m4a, .opus, .aif, .aiff, .mp2, .mka"
        onChange={handleFileChange}
      />
      <button
        className="tiptap-dropdown-item tiptap-upload-item"
        onClick={triggerFileInput}
        disabled={isUploadInProgress}
      >
        {isUploadInProgress ? (
          <>
            <FaSpinner className="tiptap-spinner" /> <span>Uploading...</span>
          </>
        ) : (
          <>
            <FaFileImage /> <span>Upload</span>
          </>
        )}
      </button>
    </div>
  );
}

export default FileUpload;
