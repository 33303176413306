import { useState, useCallback } from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Typography from "@tiptap/extension-typography";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Code from "@tiptap/extension-code";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { Node, mergeAttributes } from "@tiptap/core";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import { FloatingMenu } from "@tiptap/extension-floating-menu";
import { ColorHighlighter } from "./ColorHighlighter";
import { SmilieReplacer } from "./SmilieReplacer";
import Placeholder from "@tiptap/extension-placeholder";

const Frame = Node.create({
  name: "iframe", // unique name for the Node
  group: "block", // belongs to the 'block' group of extensions
  selectable: true, // so we can select the frame
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addOptions() {
    return {
      allowFullscreen: true,
      HTMLAttributes: {
        class: "iframe-wrapper",
      },
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
      width: {
        default: "100%",
      },
      height: {
        default: "500",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "iframe",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", this.options.HTMLAttributes, ["iframe", HTMLAttributes]];
  },

  addNodeView() {
    return ({ editor, node }) => {
      const div = document.createElement("div");
      div.className =
        "media-container" + (editor.isEditable ? " cursor-pointer" : "");
      const iframe = document.createElement("iframe");
      if (editor.isEditable) {
        iframe.className = "pointer-events-none";
      }
      iframe.width = "100%";
      iframe.height = "500";
      iframe.allowFullscreen = true;
      iframe.src = node.attrs.src;
      div.append(iframe);
      return {
        dom: div,
      };
    };
  },
});

const Audio = Node.create({
  name: "audio", // unique name for the Node
  group: "block", // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      src: {
        default: null,
      },
      controls: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "audio",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["audio", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ editor, node }) => {
      const div = document.createElement("div");
      div.className =
        "media-container" + (editor.isEditable ? " cursor-pointer" : "");
      const audioElement = document.createElement("audio");
      audioElement.controls = true;
      if (editor.isEditable) {
        audioElement.className = "pointer-events-none";
      }
      audioElement.src = node.attrs.src;
      div.appendChild(audioElement);
      return {
        dom: div,
      };
    };
  },
});

const Video = Node.create({
  name: "video", // unique name for the Node
  group: "block", // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      src: {
        default: null,
      },
      controls: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "video",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["video", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ editor, node }) => {
      const div = document.createElement("div");
      div.className =
        "media-container" + (editor.isEditable ? " cursor-pointer" : "");
      const videoElement = document.createElement("video");
      if (editor.isEditable) {
        videoElement.className = "pointer-events-none";
      }
      videoElement.src = node.attrs.src;
      div.appendChild(videoElement);
      return {
        dom: div,
      };
    };
  },
});

Image.configure({
  allowBase64: true,
});

Color.configure({
  types: ["textStyle"],
});

export function CustomEditor() {
  const [editorBody, setEditorBody] = useState("");
  const editor = useEditor({
    extensions: [
      BubbleMenu.configure({
        pluginKey: "bubbleMenuOne",
        element: document.querySelector(".menu-one"),
      }),
      BubbleMenu.configure({
        pluginKey: "bubbleMenuTwo",
        element: document.querySelector(".menu-two"),
      }),
      FloatingMenu.configure({
        element: document.querySelector(".floating-menu"),
      }),
      StarterKit,
      Image,
      Link,
      Text,
      TextStyle,
      Heading,
      Table.configure({
        resizable: true,
      }),
      HorizontalRule,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        includeChildren: true,
        placeholder: ({ node }) => {
          if (node.type.name === "detailsSummary") {
            return "Summary";
          }

          return null;
        },
      }),
      Typography,
      ColorHighlighter,
      SmilieReplacer,
      TableRow,
      TableHeader,
      TableCell,
      Document,
      Paragraph,
      Strike,
      Underline,
      Code,
      Color,
      Youtube,
      Video,
      Audio,
      Frame,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      setEditorBody(editor.getHTML());
    },
  });

  const addImage = useCallback(() => {
    if (editor == null) return;
    const url = window.prompt("URL");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  const addAudio = useCallback(() => {
    if (editor == null) return;
    const url = window.prompt("URL");

    if (url) {
      editor
        .chain()
        .focus()
        .insertContent(`<audio src="${url}" controls></audio>`)
        .run();
    }
  }, [editor]);

  const addVideo = useCallback(() => {
    if (editor == null) return;
    const url = window.prompt("URL");

    if (url) {
      editor
        .chain()
        .focus()
        .insertContent(`<video src="${url}" controls></video>`)
        .run();
    }
  }, [editor]);

  const addYoutube = useCallback(() => {
    if (editor == null) return;
    const url = window.prompt("URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      });
    }
  }, [editor]);

  const addIFrame = useCallback(() => {
    if (editor == null) return;
    const url = window.prompt("URL");

    if (url) {
      editor
        .chain()
        .focus()
        .insertContent(`<iframe src="${url}"></iframe>`)
        .run();
    }
  }, [editor]);

  const setLink = useCallback(() => {
    if (editor == null) return;
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  return {
    editor,
    editorBody,
    addImage,
    setLink,
    addAudio,
    addVideo,
    addYoutube,
    addIFrame,
  };
}
