import projectData from "../project.json";

const SiteFooter = () => {
  return (
    <footer>
      <hgroup>
        <h6>{projectData.labels.copytight_title} to the authors.</h6>
      </hgroup>
    </footer>
  );
};

export default SiteFooter;
